import { useUserStore } from 'store/user.store';
import { createError } from '#app';
import { NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';

export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore();
  if ((!userStore.userInfo || !userStore.userInfo?.supplierInfo)
    && process.server
  ) {
    await useSetServerUserInfo();
  }

  if (!userStore.isSupplierAdmin) {
    throw createError({
      statusCode: NOT_FOUND_ERROR_CODE,
    });
  }
});
